import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import * as styles from "./styles.module.scss";

const Contact = ({data: cmsData}) => {
  const seoData = cmsData.allSanitySeoMeta.nodes[0];
  return (
    <Layout type='modemagic' seoData={seoData}>
      <StructuredData />
      <div className={styles.container}>
        <div className='flex flex-col md:flex-row items-start md:content-center content-between gap-8'>
          <div className='flex flex-col items-start justify-center'>
            <h1 className='mb-12'>Contact Us</h1>
            <p>
              Please reach out to us at our{" "}
              <OutboundLink
                style={{color: "#f5c252", textDecoration: "underline"}}
                href='mailto:support@getmodemagic.com'
              >
                support@getmodemagic.com
              </OutboundLink>{" "}
              or call us at{" "}
              <OutboundLink
                style={{color: "#f5c252", textDecoration: "underline"}}
                href='tel:+12526512134'
              >
                +1 252 651 2134
              </OutboundLink>
              .
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "contact"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default Contact;
